import { makeAutoObservable } from "mobx"

export default class GroupStore {

    groups = []
    selectedGroup = null
    selectedSubject = null
    students = []
    lessons = []
    topics = []
    subjects = []
    changed = false
    blocked = false

    constructor() {
        makeAutoObservable(this)
    }

    setDefaults() {
        this.groups = []
        this.selectedGroup = null
        this.selectedSubject = null
        this.students = []
        this.topics = []
        this.subjects = []

        this.changed = false
    }

    setGroups(groups) {
        this.groups = groups
    }

    setGroupName(groupId, newName) {
        this.groups.find(g => g.id === +groupId).name = newName
    }

    setSelectedGroup(groupId) {
        this.selectedGroup = +groupId === 0 ? this.defaultValue : this.groups.find(g => g.id === +groupId)
    }

    setLessons(loadedLessons) {
        this.lessons = loadedLessons
    }
    setLessonTheme(newTheme, lessonId) {
        this.lessons.find(lesson => lesson.id === +lessonId).theme = newTheme
    }
    setLessonDay(newDay, lessonId) {
        this.lessons.find(lesson => lesson.id === +lessonId).day = newDay
    }

    setStudents(loadedStudents, month = 0, year = 0) {
        this.students = loadedStudents
        if (month === 0) {
            this.students.forEach(student => {
                student.changed = false
                student.journal = student.journals.length > 0 ? student.journals[0] : null
            })
        } else {
            this.students.forEach(student => {
                student.changed = false
                let history = []
                student.journals.forEach(journal => history.push({ period: parseInt(journal.year + (journal.month<10?'0':'') + journal.month), credit: journal.credit, debit: journal.debit }))
                history.sort((x, y) => x.period - y.period)
                let balance = 0
                let currentPeriod = parseInt(year + (month<10?'0':'') + month)
                for (let i = 0; i < history.length; i++) {
                    if (currentPeriod > history[i].period) {
                        balance = balance + history[i].debit - history[i].credit
                    }
                }
                student.balance = balance
                student.journal = null
                let currentJournal = student.journals.find(journal => (journal.month === month && journal.year === +year))
                if (currentJournal) {
                    student.journal = currentJournal
                }
            })
        }
    }

    setStudentName(studentId, newName) {
        let student = this.students.find(student => student.id === studentId)
        student.name = newName
        student.changed = true
    }

    setStudentChanged(studentId, bool) {
        this.students.find(student => student.id === studentId).changed = bool
    }

    setStudentJournal(studentId, lessonId) {
       
        let updatingStudent = this.students.find(student => student.id === studentId)
        let lessons = []
        if (updatingStudent.journal.lessons !== '') {
            lessons = updatingStudent.journal.lessons.split(';')
        }
        if (lessons.find(lesson => +lesson === +lessonId)) {
            lessons = lessons.filter(lesson => +lesson !== +lessonId)
        } else {
            lessons.push(lessonId)
        }
        updatingStudent.journal.lessons = lessons.join(';')
        this.changed = true
    }

    setChanged(bool) {
        this.changed = bool
    }

    setStudentCredit(studentId, sum) {
        let updatingStudent = this.students.find(student => student.id === studentId)
        if (updatingStudent) {
            updatingStudent.journal.credit = sum
            this.changed = true
        }
    }

    setStudentDebit(studentId, sum) {
        let updatingStudent = this.students.find(student => student.id === studentId)
        if (updatingStudent) {
            updatingStudent.journal.debit = sum
            this.changed = true
        }
    }

    setStudentInfo(studentId, info) {
        let updatingStudent = this.students.find(student => student.id === studentId)
        if (updatingStudent) {
            updatingStudent.journal.info = info
            this.changed = true
        }
    }


    setTopics(loadedTopics) {
        this.topics = loadedTopics
    }

    setSelectedSubject(subjectId) {
        this.selectedSubject = this.subjects.find(subject => subject.id === +subjectId)
    }

    setSubjects(loadedSubjects) {
        this.subjects = loadedSubjects
    }
}